@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.tt {
  color: #1d1d1d;
  /* border-bottom: 1px solid #ebebeb; */
  border-bottom: 1px solid red;
  font-family: "Gilroy-Medium ☞";
  font-weight: 500;
  padding-bottom: 20px;
  padding-left: 10px;
  margin-bottom: 20px;
}

.account-dropdown {
    font-size: 16px;
    font-family: Gilroy-Bold ☞;
    font-weight: 700;
    text-align: center;
    line-height: 26px;
}

.zoom {
  transition: transform .2s; /* Animation */
}

.zoom:hover {
  transform: scale(1.04); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}